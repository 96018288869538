import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

const Contact = ({ sharedBasicInfo, sharedContactMe }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const onSubmit = (data) => {
    const { name, email, message } = data;

    const templateParams = {
      from_name: name,
      from_email: email,
      to_email: "dorperrier@gmail.com",
      message: message,
    };

    setIsSending(true);

    emailjs
      .send("service_wp2xmfo", "template_bcand1a", templateParams, "T1jUg4K5BRqzHn__D")
      .then(() => {
        reset();
        setIsEmailSent(true);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  if (!sharedBasicInfo || !sharedContactMe) {
    return <div>Loading...</div>;
  }

  return (
    <section id="contactMe" className="contact-section pb-5">
      <div className="container">
        <h1 className="text-center mb-4">{sharedBasicInfo.section_name.contact}</h1>
        {isEmailSent ? (
          <div className="alert alert-success">{sharedContactMe.success}</div>
        ) : (
          <>
            {isSending ? (
              <div className="loading"></div>
            ) : (
              <form className="row g-3 pt-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-6">
                  <label className="form-label">{sharedContactMe.name}</label>
                  <input {...register("name", { required: true })} type="text" className="form-control" />
                  {errors.name && <span className="text-danger">{sharedContactMe.error}</span>}
                </div>
                <div className="col-md-6">
                  <label className="form-label">Email *</label>
                  <input {...register("email", { required: true })} type="email" className="form-control" />
                  {errors.email && <span className="text-danger">{sharedContactMe.error}</span>}
                </div>
                <div className="col-12 pb-3 pt-3">
                  <label className="form-label">Message *</label>
                  <textarea {...register("message", { required: true })} className="form-control" rows="5"></textarea>
                  {errors.message && <span className="text-danger">{sharedContactMe.error}</span>}
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-beige p-2">{sharedContactMe.button}</button>
                </div>
              </form>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default Contact;